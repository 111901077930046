<template>
  <div class="sheet">
    <form @submit="$root.postData">
      <div class="framing-x bg-content">
        <article class="article">
          <div class="infoBox text-primary">
            <div class="infoBox-title mb-2 mb-lg-3 pb-lg-1">職業適性分析</div>
            <div class="text-small">
              (例A) 如你覺得你是一個口才很好的人，請勾：愛說話(強)；如你覺得你是一個比較文靜的人，
              請勾：文靜(弱)；如你不喜歡與人交談，請勾：文靜(強)。
            </div>
          </div>

          <fieldset :id="'q2-' + questionIndex" v-for="(question, questionIndex) in questions" :key="questionIndex">
            <label>{{ question.title }}</label>
            <div class="row">
              <div class="col-12 col-lg-3" v-for="(option, optionIndex) in question.options" :key="optionIndex">
                <label class="form-radio text-center mb-lg-0">
                  <input type="radio" :name="'sheet2['+ questionIndex +']'" :value="optionIndex+1" v-model="$root.$data.Sheet2[questionIndex]"  @click="$root.scrollToElement(questionEl(questionIndex+1))" required />
                  <span class="form-radio-value">{{ option }}</span>
                </label>
              </div>
            </div>
          </fieldset>
        </article>
      </div>
      <div class="framing-x framing-y">
        <button class="btn btn-primary w-100 footer-button" @click="next">完成</button>
      </div>
    </form>
  </div>
</template>

<script>
import questions from '../data/questions.js'

export default {
  data() {
    return {
      questions: questions('sheet2')
    }
  },
  methods: {
    questionEl(questionIndex) {
      return document.getElementById("q2-" + questionIndex);
    },
    next(e) {
      if (!this.$root.reportFormValidity(document.querySelector('form'))) {
        e.preventDefault();
        return false;
      }
    }
  }
}
</script>
